import React from "react"
import Header from "./header"
import Footer from "./footer"

export default function Layout({ children }) {
  return (
    <div class="scroll-smooth">
    <Header></Header>
        <div style={{ margin: `0`, padding: `0` }} className="overflow-y-hidden">
            {children}
        </div>
    <Footer></Footer>
    </div>
  )
}