import React from "react"

export default function Header() {
    return (
        <div class="w-screen bg-gradient-to-r from-cyan-700 to-teal-900 fixed">
            <div class="container h-16 w-screen max-w-7xl mx-auto">
            <nav class="flex items-center justify-between flex-wrap p-4">
            <div class="flex items-center flex-shrink-0 text-white mr-6">
                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" className="fill-current h-8 w-8 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z" />
                </svg>
                <span class="font-semibold text-xl tracking-tight">What a Pain</span>
            </div>
            <div class="block mr-6">
                <button class="flex items-center px-3 py-2 border rounded text-white-200 border-white-400 text-white hover:text-white hover:border-white">
                <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                </button>
            </div>

            </nav>
            </div>
        </div>
    )
  }
